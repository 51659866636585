import React, { useEffect, useState  } from 'react';
//import { graphql } from "gatsby"
import { Link } from "gatsby"

import Layout from "components/Layout";
import Seo from 'components/Seo';
import { StaticImage } from "gatsby-plugin-image"


const Reg = ({location, data}) => {

  return (
    <Layout>
      <Seo
        title={'Digital Transit Solutions'}
        pathname={location.pathname}
      />
      <section>
        <div  className="container">
          {/*<h1>Digital Transit Solutions</h1>*/}
          <h1>اخبرنا عن طريقك</h1>
            {/*<StaticImage placeholder="blurred" className="banner"  src={'../assets/media/banner.jpg'} alt="Oryx" width={700} />*/}
          <h5>نحن متحمسون لايجاد وسائل جديدة للتنقّل اليومي بطريقة سهلة ومريحة.</h5>
        </div>
      </section>
    </Layout>
  );
}

export default Reg
